@charset 'UTF-8';

.site-page {
  html.no-js &,
  &.initialised {
    .panel-outer {
      visibility: inherit;
      opacity: 1;
    }
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
li {
  html.no-js &,
  html.font-loaded & {
    visibility: inherit;
    opacity: 1;
  }
}
